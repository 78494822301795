<template>
    <div>
        <div v-if="isLoading" class="l-stack l-gap-3 l-padded">
            <VSpinner size="medium" line-fg-color="#000" :speed="1" />
        </div>

        <template v-else>
            <AssetImageControl
                v-if="!tracker.asset_details.external_picture"
                :picture-url="tracker.asset_details.picture"
                :type-identifier="tracker.asset_details.asset_type_identifier"
                @change="assetPicture = $event"
            />

            <div class="l-new-asset-view l-stack l-gap-5 l-padded">
                <div class="l-stack l-gap-2">
                    <FormInput
                        v-model="$v.tracker.asset_details.name.$model"
                        :label="$t('name')"
                        :required="true"
                        :show-required-error="
                            $v.tracker.asset_details.name.$dirty &&
                                !$v.tracker.asset_details.name.required
                        "
                    />

                    <FormInput
                        v-model="tracker.asset_details.identification"
                        :label="$t('identification')"
                    />

                    <div>
                        <div class="form-label">
                            {{ $t('location') }}
                        </div>

                        <LocationSelect
                            v-model="tracker.location"
                            :placeholder="$t('locationSelectplaceholder')"
                            :removable="true"
                        />
                    </div>
                </div>

                <div class="l-stack l-gap-2">
                    <h3 class="t-title">{{ $t('lookOnMap') }}</h3>

                    <ColorInput
                        v-model="tracker.asset_details.color"
                        :label="$t('color')"
                    />

                    <div>
                        <div class="form-label">
                            {{ $t('type') }}
                        </div>

                        <AssetTypeSelect
                            v-model="tracker.asset_details.asset_type"
                        />
                    </div>
                </div>

                <div
                    v-if="config && configEditFormComponent"
                    class="l-stack l-gap-2"
                >
                    <h3 class="t-title">
                        {{ $t('configuration') }}
                    </h3>

                    <component
                        :is="configEditFormComponent"
                        ref="configEditForm"
                        :value="config"
                    />
                </div>

                <div v-if="hasSensorDataFields" class="l-stack l-gap-2">
                    <h3 class="t-title">{{ $t('measurements') }}</h3>

                    <label v-if="initialValues.odometer !== undefined">
                        <p class="form-label">
                            {{ $t('shared.measurements.odometer') }}
                        </p>

                        <BaseInput v-model="odometer">
                            <template #postfix>km</template>
                        </BaseInput>
                    </label>

                    <label v-if="initialValues.runningTime !== undefined">
                        <p class="form-label">{{ $t('runningTime') }}</p>

                        <div class="l-inline l-gap-1">
                            <BaseInput v-model="runningHours">
                                <template #postfix>h</template>
                            </BaseInput>

                            <BaseInput v-model="runningMinutes">
                                <template #postfix>m</template>
                            </BaseInput>
                        </div>
                    </label>
                </div>

                <div v-if="additionalData.length" class="l-stack l-gap-2">
                    <h3 class="t-title">{{ $t('customerFields') }}</h3>

                    <template v-for="item in additionalData">
                        <label
                            v-if="item.type === 'boolean'"
                            :key="item.key"
                            class="l-inline l-center-v l-gap-1"
                        >
                            <input
                                v-model="
                                    tracker.asset_details.additional_data[
                                        item.key
                                    ]
                                "
                                type="checkbox"
                            />

                            <span>{{ item.label }}</span>
                        </label>

                        <div v-else :key="item.key">
                            <p class="form-label">{{ item.label }}</p>

                            <BaseMultiselect
                                v-if="item.enum"
                                v-model="
                                    tracker.asset_details.additional_data[
                                        item.key
                                    ]
                                "
                                :options="item.enum"
                                :value="item.value"
                            />

                            <DateInput
                                v-else-if="item.format === 'date'"
                                v-model="
                                    tracker.asset_details.additional_data[
                                        item.key
                                    ]
                                "
                            />

                            <BaseInput
                                v-else-if="item.type === 'number'"
                                v-model.number="
                                    tracker.asset_details.additional_data[
                                        item.key
                                    ]
                                "
                                type="number"
                            />

                            <BaseInput
                                v-else
                                v-model.trim="
                                    tracker.asset_details.additional_data[
                                        item.key
                                    ]
                                "
                            />
                        </div>
                    </template>
                </div>

                <div v-if="hasMaintenanceAccess" class="l-stack l-gap-2">
                    <h3 class="t-title">{{ $t('maintenancePolicies') }}</h3>

                    <BaseMultiselect
                        v-model="selectedPolicies"
                        :options="maintenancePolicies"
                        :custom-label="option => option.name"
                        track-by="id"
                        multiple
                    />
                </div>

                <div v-if="isAdminOrStaff" class="l-stack l-gap-2">
                    <h3 class="t-title">{{ $t('rights') }}</h3>

                    <div>
                        <div class="form-label">
                            {{ $t('usersViewingAccess') }}
                        </div>

                        <UserSelect
                            v-model="usersViewingAccess"
                            :exclude="[
                                tracker.asset_details.owner,
                                ...usersEditingAccess,
                            ]"
                            :multiple="true"
                        />
                    </div>

                    <div>
                        <div class="form-label">
                            {{ $t('usersEditingAccess') }}
                        </div>

                        <UserSelect
                            v-model="usersEditingAccess"
                            :exclude="[
                                tracker.asset_details.owner,
                                ...usersViewingAccess,
                            ]"
                            :multiple="true"
                        />
                    </div>

                    <VDialog />
                </div>

                <div
                    v-if="isStatic && showLocationEdit"
                    class="l-stack l-gap-2"
                >
                    <h3 class="t-title">{{ $t('changeAssetPosition') }}</h3>

                    <p class="t-small t-subtle">
                        {{ $t('changeAssetPositionDescription') }}
                    </p>

                    <div>
                        <div class="form-label">
                            {{ $t('changeStaticPositionToAdress') }}
                        </div>

                        <div class="l-inline l-gap-2">
                            <AddressSearch
                                :proximity-longitude="
                                    tracker.asset_details.position.longitude
                                "
                                :proximity-latitude="
                                    tracker.asset_details.position.latitude
                                "
                                @input="setAddress"
                            />

                            <IconButton
                                v-if="isGeolocationAvailable"
                                :disabled="isGeolocationLoading"
                                @click="handleDeviceLocate"
                            >
                                <LocationTargetIcon />
                            </IconButton>
                        </div>
                    </div>
                </div>

                <FormNavigation @save="save" />
            </div>
        </template>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { isEmpty } from 'lodash'
import VSpinner from 'vue-simple-spinner'

import { httpHelper, languageHelper } from '@/utils'
import AddressSearch from '../AddressSearch'
import AssetImageControl from '../AssetImageControl'
import AssetTypeSelect from '../AssetTypeSelect'
import BaseInput from '../redesigned/BaseInput'
import BaseMultiselect from '../redesigned/BaseMultiselect'
import ColorInput from '../ColorInput'
import DateInput from '../redesigned/DateInput'
import EditConfigAlpscout from '../EditConfigAlpscout'
import EditConfigComtacTd1 from '../EditConfigComtacTd1'
import FormInput from '../FormInput'
import FormNavigation from '../FormNavigation'
import IconButton from '../IconButton'
import LocationSelect from '../LocationSelect'
import LocationTargetIcon from '../icons/LocationTargetIcon'
import UserSelect from '../UserSelect'

export default {
    name: 'EditAssetView',
    components: {
        AddressSearch,
        AssetImageControl,
        AssetTypeSelect,
        BaseInput,
        BaseMultiselect,
        ColorInput,
        DateInput,
        EditConfigAlpscout,
        EditConfigComtacTd1,
        FormInput,
        FormNavigation,
        IconButton,
        LocationSelect,
        LocationTargetIcon,
        UserSelect,
        VSpinner,
    },
    props: {
        id: {
            type: [Number, String],
            required: true,
        },
        shoudRedirectOnSave: {
            type: Boolean,
            default: true,
        },
        showLocationEdit: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            address: null,
            assetPicture: null,
            changesAccepted: false,
            initialValues: {},
            isGeolocationAvailable: !!navigator.geolocation,
            isGeolocationLoading: false,
            isLoading: true,
            runningHours: null,
            runningMinutes: null,
            selectedPolicies: [],
            tracker: null,
            usersEditingAccess: null,
            usersViewingAccess: null,
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.changesAccepted || !this.changed) {
            next()
            return
        }

        this.$modal.show('dialog', {
            title: this.$t('warning'),
            text: this.$t('changesUnsaved'),
            buttons: [
                {
                    title: this.$t('shared.yes'),
                    handler: () => {
                        // Reset tracker location
                        this.updateAsset({
                            id: this.tracker.asset_details.id,
                            position: this.initialValues.position,
                        })

                        if (
                            to.name !== 'detail' &&
                            to.params.id !== this.tracker.asset
                        ) {
                            this.setActiveTrackerOnMap(null)
                        }

                        this.$modal.hide('dialog')
                        next()
                    },
                },
                {
                    title: this.$t('shared.no'),
                },
            ],
        })
    },
    computed: {
        ...mapState('tracker', ['trackers']),
        ...mapState('maintenance', ['maintenancePolicies']),
        ...mapState('map', ['mapInstance']),
        ...mapGetters('auth', ['hasMaintenanceAccess', 'isAdminOrStaff']),
        ...mapGetters('tracker', ['assetTypesById']),
        additionalData() {
            const schema = this.assetTypesById[
                this.tracker.asset_details.asset_type
            ]?.additional_data_schema.properties

            return schema
                ? Object.keys(schema).map(key => ({
                      key,
                      type: schema[key].type,
                      format: schema[key].format,
                      label:
                          schema[key].label?.[this.$i18n.locale] ||
                          schema[key].label?.[languageHelper.defaultLanguage] ||
                          key,
                      enum: schema[key].enum,
                  }))
                : []
        },
        configEditFormComponent() {
            if (this.tracker.model?.startsWith('alptracker-alpscout')) {
                return EditConfigAlpscout
            } else if (
                this.tracker.asset_details.asset_type_type === 'comtac-td1'
            ) {
                return EditConfigComtacTd1
            }

            return null
        },
        isAdditionalDataChanged() {
            return (
                this.initialValues.additionalData &&
                this.additionalData.some(
                    ({ key }) =>
                        this.initialValues.additionalData[key] !==
                        this.tracker.asset_details.additional_data[key]
                )
            )
        },
        isMaintenancePolicyChanged() {
            return (
                this.hasMaintenanceAccess &&
                (this.initialValues.maintenancePolicies?.length !==
                    this.selectedPolicies?.length ||
                    this.initialValues.maintenancePolicies?.some(
                        ({ id }, i) => id !== this.selectedPolicies[i].id
                    ))
            )
        },
        isStatic() {
            return this.tracker.asset_details.static
        },
        position() {
            return this.trackers.find(item => item.id == this.id).asset_details
                .position
        },
        changed() {
            return (
                this.initialValues.usersEditingAccess !==
                    this.usersEditingAccess ||
                this.initialValues.usersViewingAccess !==
                    this.usersViewingAccess ||
                this.initialValues.position.longitude !==
                    this.position.longitude ||
                this.initialValues.position.latitude !==
                    this.position.latitude ||
                this.initialValues.name !== this.tracker.asset_details.name ||
                this.initialValues.picture !==
                    this.tracker.asset_details.picture ||
                this.initialValues.identification !==
                    this.tracker.asset_details.identification ||
                this.initialValues.assetType !==
                    this.tracker.asset_details.asset_type ||
                this.initialValues.color !== this.tracker.asset_details.color ||
                this.initialValues.location !== this.tracker.location ||
                this.initialValues.odometer !== this.odometer * 1000 ||
                this.initialValues.runningTime !==
                    (this.runningHours * 60 + this.runningMinutes) * 60 ||
                this.isAdditionalDataChanged ||
                this.isMaintenancePolicyChanged ||
                this.$refs.configEditForm?.isChanged
            )
        },
        hasSensorDataFields() {
            return (
                this.initialValues.odometer !== undefined ||
                this.initialValues.runningTime !== undefined
            )
        },
        hasWritePermission() {
            return this.tracker?.asset_details?.permissions?.access === 'WRITE'
        },
    },
    watch: {
        id() {
            this.config = null
            this.loadData()
        },
    },
    mounted() {
        this.loadData()
    },
    methods: {
        ...mapMutations('tracker', [
            'setActiveTrackerOnMap',
            'updateAsset',
            'updateTracker',
        ]),
        getSensorDataPayload() {
            const payload = {}

            if (typeof this.initialValues.odometer === 'number') {
                const value = this.odometer * 1000
                if (this.initialValues.odometer !== value) {
                    payload.odometer = value
                }
            }

            if (typeof this.initialValues.runningTime === 'number') {
                const value =
                    this.runningHours * 3600 + this.runningMinutes * 60
                if (this.initialValues.runningTime !== value) {
                    payload.running_time = value
                }
            }

            return payload
        },
        handleDeviceLocate() {
            this.isGeolocationLoading = true
            navigator.geolocation.getCurrentPosition(
                ({ coords: { latitude, longitude } }) => {
                    this.isGeolocationLoading = false
                    this.mapInstance.setView([latitude, longitude], 16)
                    this.updateAsset({
                        id: this.tracker.asset_details.id,
                        position: { latitude, longitude },
                    })
                    this.$notify({
                        text: this.$t('deviceLocatingSucceed'),
                    })
                },
                () => {
                    this.isGeolocationLoading = false
                    this.$notify({
                        text: this.$t('deviceLocatingFailed'),
                    })
                }
            )
        },
        async handleMaintenancePolicyUpdate() {
            for (const policy of this.initialValues.maintenancePolicies) {
                if (!this.selectedPolicies.find(({ id }) => id === policy.id)) {
                    await httpHelper.drop(
                        `maintenance-policy-assignments/${policy.assignmentId}/`
                    )
                }
            }

            for (const policy of this.selectedPolicies) {
                if (
                    !this.initialValues.maintenancePolicies.find(
                        ({ id }) => id === policy.id
                    )
                ) {
                    await httpHelper.post('maintenance-policy-assignments/', {
                        asset: this.tracker.asset,
                        policy: policy.id,
                        last_performed: new Date().toJSON(),
                        last_performed_value: null,
                    })
                }
            }
        },
        async loadData() {
            this.tracker = JSON.parse(
                JSON.stringify(this.trackers.find(item => item.id == this.id))
            ) // Making deep copy

            this.initialValues.additionalData = {
                ...this.tracker.asset_details.additional_data,
            }
            this.initialValues.assetType = this.tracker.asset_details.asset_type
            this.initialValues.color = this.tracker.asset_details.color
            this.initialValues.identification = this.tracker.asset_details.identification
            this.initialValues.location = this.tracker.location
            this.initialValues.name = this.tracker.asset_details.name
            this.initialValues.odometer = this.tracker.asset_details.sensor_data.odometer?.value
            this.initialValues.picture = this.tracker.asset_details.picture
            this.initialValues.position = this.tracker.asset_details.position
            this.initialValues.runningTime = this.tracker.asset_details.sensor_data.running_time?.value
            this.setActiveTrackerOnMap(this.tracker)

            if (typeof this.initialValues.odometer === 'number') {
                const { odometer } = this.initialValues
                this.odometer = odometer / 1000
            }

            if (typeof this.initialValues.runningTime === 'number') {
                const { runningTime } = this.initialValues
                this.runningHours = Math.floor(runningTime / 3600)
                this.runningMinutes =
                    Math.round(runningTime / 60) - this.runningHours * 60
            }

            if (this.hasMaintenanceAccess) {
                const policies = []
                let url =
                    'maintenance-policy-assignments/' +
                    `?limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}` +
                    `&asset=${this.tracker.asset}`

                while (url) {
                    const { data } = await httpHelper.get(url)
                    policies.push(...data.results)
                    url = data.next
                }

                this.selectedPolicies = policies.map(assignment => ({
                    assignmentId: assignment.id,
                    id: assignment.policy,
                    name: assignment.policy_name,
                }))

                this.initialValues.maintenancePolicies = this.selectedPolicies.slice()
            }

            if (this.hasWritePermission) {
                this.isLoading = true
                const { data } = await httpHelper.get(`trackers/${this.id}/`)
                this.config = data.config
            }

            if (this.isAdminOrStaff) {
                this.isLoading = true
                await this.loadRights()
            }

            this.isLoading = false
        },
        async loadRights() {
            const { data } = await httpHelper.get(
                `/asset-permissions/?asset=${this.tracker.asset}`
            )

            this.usersViewingAccess = data.results
                .filter(item => item.access === 'READ')
                .map(item => item.user)

            this.usersEditingAccess = data.results
                .filter(item => item.access === 'WRITE')
                .map(item => item.user)

            this.initialValues.usersViewingAccess = this.usersViewingAccess
            this.initialValues.usersEditingAccess = this.usersEditingAccess
        },
        isValid() {
            this.$v.$touch()
            return !this.$v.$invalid
        },
        setAddress(addressObject) {
            if (!addressObject?.geometry?.coordinates) {
                return
            }

            const [longitude, latitude] = addressObject.geometry.coordinates
            this.updateAsset({
                id: this.tracker.asset_details.id,
                position: { latitude, longitude },
            })
            this.mapInstance.setView([latitude, longitude], 16)
        },
        async save() {
            if (
                !this.isValid() ||
                this.$refs.configEditForm?.isValid?.() === false
            ) {
                return
            }
            this.changesAccepted = true
            await httpHelper.patch(`/assets/${this.tracker.asset}/`, {
                name: this.tracker.asset_details.name,
                identification: this.tracker.asset_details.identification,
                color: this.tracker.asset_details.color,
                asset_type: this.tracker.asset_details.asset_type,
                position: this.position,
                ...(this.isAdditionalDataChanged && {
                    additional_data: this.tracker.asset_details.additional_data,
                }),
            })

            if (this.assetPicture) {
                const payload = new FormData()
                payload.append(
                    'picture',
                    this.assetPicture.data,
                    this.assetPicture.name
                )
                await httpHelper.patch(
                    `/assets/${this.tracker.asset}/`,
                    payload,
                    {
                        headers: { 'Content-Type': 'multipart/form-data' },
                    }
                )
            }

            const sensorData = this.getSensorDataPayload()
            if (!isEmpty(sensorData)) {
                this.tracker.asset_details.sensor_data = await httpHelper.patch(
                    `/assets/${this.tracker.asset}/sensor-data/`,
                    sensorData
                )
            }

            if (this.isMaintenancePolicyChanged) {
                await this.handleMaintenancePolicyUpdate()
            }

            if (this.$refs.configEditForm?.isChanged) {
                await this.$refs.configEditForm.save(this.tracker)
            }

            const { data: tracker } = await httpHelper.patch(
                `/trackers/${this.tracker.id}/`,
                {
                    location: this.tracker.location,
                }
            )

            if (this.isAdminOrStaff) {
                this.manageAccess()
            }

            this.updateTracker(tracker)
            this.$emit('saved')

            this.isLoading = false

            if (this.shoudRedirectOnSave) {
                this.$router.push({
                    name: 'detail',
                    params: { id: this.id },
                })
            }
        },
        async manageAccess() {
            const { data } = await httpHelper.get(
                `/asset-permissions/?asset=${this.tracker.asset}`
            )

            const usersViewingAccessExisting = data.results
                .filter(item => item.access === 'READ')
                .map(item => item.user)

            const usersEditingAccessExisting = data.results
                .filter(item => item.access === 'WRITE')
                .map(item => item.user)

            data.results.forEach(item => {
                if (
                    item.access === 'READ' &&
                    this.usersEditingAccess.includes(item.user)
                ) {
                    usersEditingAccessExisting.push(item.user)
                    return httpHelper.patch(`/asset-permissions/${item.id}/`, {
                        access: 'WRITE',
                    })
                }

                if (
                    item.access === 'WRITE' &&
                    this.usersViewingAccess.includes(item.user)
                ) {
                    usersViewingAccessExisting.push(item.user)
                    return httpHelper.patch(`/asset-permissions/${item.id}/`, {
                        access: 'READ',
                    })
                }

                if (
                    (item.access === 'READ' &&
                        !this.usersViewingAccess.includes(item.user)) ||
                    (item.access === 'WRITE' &&
                        !this.usersEditingAccess.includes(item.user))
                ) {
                    return httpHelper.drop(`/asset-permissions/${item.id}/`)
                }
            })

            this.usersViewingAccess.forEach(user => {
                if (!usersViewingAccessExisting.includes(user)) {
                    httpHelper.post('/asset-permissions/', {
                        asset: this.tracker.asset,
                        user,
                        access: 'READ',
                    })
                }
            })

            this.usersEditingAccess.forEach(user => {
                if (!usersEditingAccessExisting.includes(user)) {
                    httpHelper.post('/asset-permissions/', {
                        asset: this.tracker.asset,
                        user,
                        access: 'WRITE',
                    })
                }
            })
        },
    },
    validations() {
        return {
            tracker: {
                asset_details: {
                    name: {
                        required,
                    },
                },
            },
        }
    },
}
</script>

<i18n>
{
    "en": {
        "changeAssetPosition": "Change Asset Position",
        "changeAssetPositionDescription": "You can change the location of this asset on the map. To do so, simply drag the asset to the new location on the map. Alternatively, you can search and select an address in the following field. The asset will then be moved to that address.",
        "changeStaticPositionToAdress": "Change the location of the asset to the following address:",
        "changesUnsaved": "Continue without saving?",
        "color": "Color",
        "configuration": "Configuration",
        "customerFields": "Customer defined fields",
        "deviceLocatingFailed": "Failed to get the device location",
        "deviceLocatingSucceed": "The current device location has been set as the location of the asset",
        "identification": "Identification",
        "location": "Location",
        "locationSelectplaceholder": "Assign the tracker to a location",
        "lookOnMap": "Appearance on the map",
        "maintenancePolicies": "Maintenance policies",
        "measurements": "Measurements",
        "name": "Name",
        "rights": "Rights",
        "runningTime": "Operating hours",
        "setMarker": "Change marker map location",
        "staticAsset": "Static asset",
        "type": "Type",
        "usersEditingAccess": "Users with editing access",
        "usersViewingAccess": "Users with viewing access",
        "warning": "Warning!"
    },
    "de": {
        "changeAssetPosition": "Asset Position ändern",
        "changeAssetPositionDescription": "Sie können die Position dieses Assets auf der Karte ändern. Dazu ziehen Sie einfach das Asset mit der Maus auf der Karte zur neuen Position. Alternativ können Sie in folgendem Feld eine Adresse suchen und auswählen. Das Asset wird dann an diese Adresse verschoben.",
        "changeStaticPositionToAdress": "Position des Assets zu folgender Adresse ändern:",
        "changesUnsaved": "Weiterfahren ohne speichern?",
        "color": "Farbe",
        "configuration": "Konfiguration",
        "customerFields": "Benutzerdefinierte Felder",
        "deviceLocatingFailed": "Abfrage des Gerätestandorts fehlgeschlagen",
        "deviceLocatingSucceed": "Der aktuelle Standort des Geräts wurde als Standort des Assets festgelegt",
        "identification": "Identifikation",
        "location": "Standort",
        "locationSelectplaceholder": "Tracker einem Standort zuweisen",
        "lookOnMap": "Aussehen auf der Karte",
        "maintenancePolicies": "Wartungspläne",
        "measurements": "Messungen",
        "name": "Name",
        "rights": "Rechte",
        "runningTime": "Betriebsstunden",
        "setMarker": "Markerposition auf der Karte verändern",
        "staticAsset": "Statisches Asset",
        "type": "Typ",
        "usersEditingAccess": "Benutzer mit Schreibzugriff",
        "usersViewingAccess": "Benutzer mit Lesezugriff",
        "warning": "Warnung!"
    },
    "fr": {
        "changeAssetPosition": "Modifier l'emplacement de l'asset",
        "changeAssetPositionDescription": "Vous pouvez modifier l'emplacement de cet asset sur la carte. Pour ce faire, il suffit de faire glisser l'asset vers le nouvel emplacement sur la carte. Vous pouvez également rechercher et sélectionner une adresse dans le champ suivant. L'asset sera alors déplacée vers cette adresse.",
        "changeStaticPositionToAdress": "Modifier l'emplacement de l'asset à l'adresse suivante:",
        "changesUnsaved": "Continuer sans sauvegarder ?",
        "color": "Couleur",
        "configuration": "Configuration",
        "customerFields": "Champs définis par le client",
        "deviceLocatingFailed": "Échec de l'obtention de l'emplacement de l'appareil",
        "deviceLocatingSucceed": "L'emplacement actuel de l'appareil a été défini comme l'emplacement du bien.",
        "identification": "Identification",
        "location": "Emplacement",
        "locationSelectplaceholder": "Affecter le tracker à un emplacement",
        "lookOnMap": "Apparance sur la carte",
        "maintenancePolicies": "Politiques de maintenance",
        "measurements": "Mesures",
        "name": "Nom",
        "rights": "Droits",
        "runningTime": "Heures de fonctionnement",
        "setMarker": "Modifier l'emplacement du marqueur sur la carte",
        "staticAsset": "Asset statique",
        "type": "Type",
        "usersEditingAccess": "Utilisateurs avec accès à l'écriture",
        "usersViewingAccess": "Utilisateurs avec accès à la lecture",
        "warning": "Attention!"
    },
    "it": {
        "changeAssetPosition": "Cambia posizione patrimoniale",
        "changeAssetPositionDescription": "È possibile modificare la posizione di questo asset sulla mappa. Per farlo, è sufficiente trascinare l'asset nella sua nuova posizione sulla mappa. In alternativa, è possibile cercare e selezionare un indirizzo nel seguente campo. Il bene sarà quindi spostato a quell'indirizzo.",
        "changeStaticPositionToAdress": "Cambiare la posizione del bene al seguente indirizzo:",
        "changesUnsaved": "Continua senza salvare?",
        "color": "Colore",
        "configuration": "Configurazione",
        "customerFields": "Campi definiti dal cliente",
        "deviceLocatingFailed": "Impossibile ottenere la posizione del dispositivo",
        "deviceLocatingSucceed": "La posizione attuale del dispositivo è stata impostata come posizione dell'asset.",
        "identification": "Identificazione",
        "location": "Locazione",
        "locationSelectplaceholder": "Locazione di Tracker",
        "lookOnMap": "Aspetto sulla mappa",
        "maintenancePolicies": "Politiche di manutenzione",
        "measurements": "Misure",
        "name": "Nome",
        "rights": "Diritti",
        "runningTime": "Ore di funzionamento",
        "setMarker": "Cambia la posizione dell'indicatore sulla mappa",
        "staticAsset": "Risorsa statica",
        "type": "Typo",
        "usersEditingAccess": "Utente con accesso in scrittura",
        "usersViewingAccess": "Utente con accesso in letturas",
        "warning": "Attenzione!"
    }
}
</i18n>
